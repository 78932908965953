import React from 'react';

// Component imports
import { Section, Text, Button } from '@utils';

const CTA = () => {
  return (
    <div className="bg-gradient-to-r from-reef to-coral">
      <Section id="cta">
        <div className="lg:space-y-16 space-y-8 text-center">
          <Text style="h2" tag="h2">
            <span className="text-white">
              Looking to get a plugin? Contact us today!
            </span>
          </Text>
          <Button type="internal" variant="secondary" href="/contact">
            Contact Us
          </Button>
        </div>
      </Section>
    </div>
  );
};

export default CTA;
