import React from 'react';

// Component imports
import { GatsbyImage } from 'gatsby-plugin-image';
import { m as motion } from 'framer-motion';
import { Link } from 'gatsby';
import { Text } from '@utils';
import Dayjs from 'dayjs';

interface Props {
  title: string;
  author: string;
  image: any;
  publishDate: string;
  slug: string;
  authorImage: any;
}

const Post = ({
  title,
  author,
  image,
  authorImage,
  publishDate,
  slug,
}: Props) => {
  return (
    <article>
      <Link to={`/blog/${slug}`} className="block">
        <motion.div
          initial="hidden"
          whileHover="hover"
          whileFocus="hover"
          className="rounded-2xl lg:h-80 relative flex flex-col w-full h-64 overflow-hidden bg-gray-900"
        >
          <div className="absolute inset-0">
            <GatsbyImage
              image={image}
              alt={title}
              className="rounded-2xl w-full h-full"
            />
            <div className="opacity-90 rounded-2xl bg-gradient-to-tr from-gray-700 to-coral absolute inset-0"></div>
          </div>
          <div className="rounded-2xl lg:space-y-10 absolute inset-0 z-10 flex flex-col flex-1 p-6 space-y-5">
            <div className="flex items-center flex-1">
              <Text style="h3" tag="h3">
                {title}
              </Text>
            </div>
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <GatsbyImage
                  image={authorImage}
                  alt=""
                  className="w-6 h-6 rounded-full"
                />
                <Text>{author}</Text>
              </div>
              <div>
                <p className="text-sm font-medium">
                  {Dayjs(publishDate).format('MM/DD/YY')}
                </p>
              </div>
            </div>
          </div>

          <motion.div
            transition={{
              type: 'spring',
              duration: 0.5,
              stiffness: 50,
              mass: 0.5,
            }}
            variants={{
              hover: { y: '0%' },
              hidden: { y: '100%' },
            }}
            className="bg-gradient-to-tr from-reef to-coral rounded-2xl absolute inset-0 flex flex-col items-center justify-center h-full p-6 space-y-3 select-none"
          >
            &nbsp;
          </motion.div>
        </motion.div>
      </Link>
    </article>
  );
};

export default Post;
