import React from "react";

import {  useStaticQuery,  graphql } from "gatsby";

// Component imports
import {  PageProps } from "gatsby";
import {  Section,  Text,  Button } from "@utils";
import Post from "@component/Blog/Post";
import SEO from "@component/SEO";
import CTA from "@component/CTA";
import Reveal from "@global/Reveal";

const Blog: React.FC<PageProps> = () => {
	// Queries
	const data = useStaticQuery(graphql`
		{
			allSanityPosts {
				edges {
					node {
						_createdAt
						_id
						title
						slug {
							current
						}
						image {
							asset {
								gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
							}
						}
						author {
							name
							avatar {
								asset {
									gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
								}
							}
						}
					}
				}
			}
		}
	`);

	return (
		<>
			<SEO
				title="Blog"
				path="/blog"
				description="Check out Coral Dev's blog where we post tutorials, opinions, guides, and general commentrary on running a modern Minecraft server. Our authors include team members and guest contributors."
			/>

			<Section id="posts">
				<div className="md:space-y-12 lg:space-y-24 space-y-8">
					<Text style="h2" tag="h1" className="text-center">
						<span className="bg-clip-text bg-gradient-to-r from-coral to-reef text-transparent">
							Thanks for checking out our blog!
						</span>
					</Text>
					<div className="md:grid-cols-2 2xl:grid-cols-3 grid gap-8">
						{data.allSanityPosts.edges.map(({ node }: any) => (
							<Reveal stagger={0.25}>
								<Post
									key={node._id}
									title={node.title}
									author={node.author.name}
									publishDate={node._createdAt}
									authorImage={node.author.avatar.asset.gatsbyImageData}
									image={node.image.asset.gatsbyImageData}
									slug={node.slug.current}
								/>
							</Reveal>
						))}
					</div>
				</div>
			</Section>
			<CTA />
		</>
	);
};

export default Blog;
